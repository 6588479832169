// extracted by mini-css-extract-plugin
export var ArtistDescription = "ChazBojorquez-module--ArtistDescription--uJ8E4";
export var ArtistInfos = "ChazBojorquez-module--ArtistInfos--ihZBH";
export var ButtonWrapper = "ChazBojorquez-module--ButtonWrapper --+4eBs";
export var CardWrapper = "ChazBojorquez-module--CardWrapper--jUP64";
export var CarrouselWrapper2 = "ChazBojorquez-module--CarrouselWrapper2--0EMDU";
export var Citations = "ChazBojorquez-module--Citations--kx2o3";
export var DescriptionWrapper = "ChazBojorquez-module--DescriptionWrapper--KvLUx";
export var ImageWrapper = "ChazBojorquez-module--ImageWrapper--FJ5tF";
export var LinkWrapper = "ChazBojorquez-module--LinkWrapper--nrU-1";
export var MobileProtrait = "ChazBojorquez-module--MobileProtrait--Dgt2z";
export var More = "ChazBojorquez-module--More--Y-gVS";
export var MoreButton = "ChazBojorquez-module--MoreButton--cd-nL";
export var NameWrapper = "ChazBojorquez-module--NameWrapper--+3hn7";
export var PdpWrapper = "ChazBojorquez-module--PdpWrapper--fuOUY";
export var PhotosWrapper = "ChazBojorquez-module--PhotosWrapper--tXKzQ";
export var ProfilWrapper = "ChazBojorquez-module--ProfilWrapper--NNwn-";
export var TitleWrapper = "ChazBojorquez-module--TitleWrapper--HglPQ";
export var Wrapper = "ChazBojorquez-module--Wrapper--O8xUn";