import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./ChazBojorquez.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Carrousel from "../../../../components/Carrousel";
import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import pdp from '../../../../res/bojorquez/portrait.jpg'
import ChazBojorquezPhoto1 from "../../../../res/Photos site/Chaz Bojorquez/Chaz Bojorquez_ by Liz O.Baylen_Los Angeles Times.jpg"
import ChazBojorquezPhoto2 from "../../../../res/Photos site/Chaz Bojorquez/Chaz Bojorquez_Los angeles times.jpg"
import ChazBojorquezPhoto3 from "../../../../res/Photos site/Chaz Bojorquez/street-chaz-bojorquez.jpg"
import ChazBojorquezPhoto4 from "../../../../res/Photos site/Chaz Bojorquez/Profil.jpg"
import ChazBojorquezPhoto5 from "../../../../res/Photos site/Chaz Bojorquez/5.jpg"
import ChazBojorquezPhoto6 from "../../../../res/Photos site/Chaz Bojorquez/6.jpg"
import ChazBojorquezPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Chaz Bojorquez - End of the World- 2012.jpeg"
import ChazBojorquezPhoto8 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Chez Bojorquez - El vato loco.jpeg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/bojorquez/past-present-1.jpg"
import PastPresent2 from "../../../../res/bojorquez/past-present-2.jpg"
import PastPresent3 from "../../../../res/bojorquez/past-present-3.jpg"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "ChazBojorquez",
  name: "ChazBojorquez",
  description: 
  "Charles \"Chaz\" Bojórquez, born in 1949, was one of the first graffiti artists in Los Angeles. The Mexican-American artist grew up in L.A. in the 1950s and 1960s, where he was introduced to the old tradition of cholo writing, which was the preserve of Latino gangs on the east side of the city. In 1979, he began a long journey that would take him to more than 35 countries where it will deepen the techniques of graphic and calligraphy. His raw and imposing style, and all the territoriality it gave off, prompted Bojórquez to start tagging. His work can be found in important public collections such as the National Museum of American Art in Washington, D.C., the Orange County Museum of Art, the Smithsonian Institute, the National Museum of American Art, LACMA, MOCA, the National Hispanic Cultural Center and the Laguna Art Museum. His work has been featured in numerous exhibitions such as at the L.A Louver Galleries in the exhibition Roll Call: 11 Artists from L.A. in 2017 or at the National Hispanic cultural center art museum in Albuquerque for the presentation of their new artist selection in their permanent collection.",
  pdp: pdp,
  alt_pdp: "Photo de profil de ChazBojorquez.",
  photos: [
    { src: PastPresent1, name: "ChazBojorquez" },
    { src: PastPresent2, name: "ChazBojorquez" },
    { src: PastPresent3, name: "ChazBojorquez" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: ChazBojorquezPhoto7, name: "Chaz Bojorquez", artist: "Chaz Bojorquez (né en 1949)", title:"End of the world, 2012", desc1:"Acrylique sur toile", desc2:"Acrylic on canva", desc3:"203 x 186 cm", desc4:"72 3⁄4 x 73 1⁄8 in.", desc5:"Signé, daté au devant.", desc6:"Signed and dated on the front", desc7: "- Collection particulière", desc8:"<h4>-<i> Chaz Bojorquez - \"End of the World\"</i> @ Plaza De La Raza / Boathouse Gallery - December 21st 2012 - January 31th 2013</h4>"  },
    { src: ChazBojorquezPhoto8, name: "Chaz Bojorquez", artist: "Chaz Bojorquez (né en 1949)", title:"Untitled, 2013", desc1:"Acrylique sur toile", desc2:"Acrylic on canva", desc3:"183 x 215 cm", desc4:"72 1⁄8 x 84 1⁄2 in.", desc7: "- Collection particulière.", desc8 :"<h4>-<i> Chaz Bojorquez - \"End of the World\"</i> @ Plaza De La Raza / Boathouse Gallery - December 21st 2012 - January 31th 2013</h4>"},
  ],
};

const ChazBojorquez = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>CHAZ BAJORQUEZ <h2 style={{paddingLeft: "16px"}}>(Charles Bajorquez- American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1949</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }}
            >
              Contact-us
            </Button>
        </div>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default ChazBojorquez;